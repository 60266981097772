import React from "react";
import { BsFillPlayFill } from "react-icons/bs";
import video from "../images/dummy-video.mp4";
const AboutUs = () => {
  return (
    <>
      <section className="about-us-page-section">
        <div className="video-container">
          <div className="container">
            <div className="video">
              <video src={video} controls="controls" autoplay="true" />
            </div>
            <a class="video-play-button" href="#">
              <span>
                <BsFillPlayFill />
              </span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutUs;
